import { AppStore } from 'src/app/store';
import { NegotiationsHistory } from 'src/models/negotiationsHistory';
import fetcher from 'src/utils/fetcher';

const RESUME_HISTORY_URL = '/shards/resume/history';

interface QueryParams {
    resumeHash?: string;
    vacancyId?: string;
    maxEvents?: number;
}

declare global {
    interface FetcherGetApi {
        [RESUME_HISTORY_URL]: {
            queryParams: QueryParams;
            response: { vacancies: AppStore['negotiationsHistory'] };
        };
    }
}
export const fetchResumeHistory = ({
    resumeHash,
    vacancyId,
}: QueryParams): Promise<{ vacancies: NegotiationsHistory[] }> =>
    fetcher.get(RESUME_HISTORY_URL, { params: { resumeHash, vacancyId } });
