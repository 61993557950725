import { useMemo } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { DataProvider, DataProviderResult } from '@hh.ru/magritte-ui';

import {
    buildDataProvider,
    getAutosuggestItems,
    AutosuggestWithText,
    SuggestUrl,
} from 'src/utils/suggest/buildDataProvider';

export interface SpecializationDTO {
    id: number;
    name: string;
    profarea_id: number;
    profarea_name: string;
}
export interface ProfessionalRoleDTO {
    id: string;
    name: string;
    incompleteResumeResponsesAllowed?: boolean;
}

export interface PositionDTO extends AutosuggestWithText {
    specializations?: SpecializationDTO[];
    professionalRoles?: ProfessionalRoleDTO[];
    text: string;
}

export function createSearchFormatter<T extends AutosuggestWithText & { text: string }>() {
    return function (items: T[] | null): DataProviderResult<T> {
        if (!items) {
            return [];
        }

        return items.map((item) => ({
            type: 'cells',
            items: [
                {
                    data: item,
                    value: item.text,
                },
            ],
        }));
    };
}

export const usePositionDataProvider = (): DataProvider<PositionDTO> => {
    const lang = useSelector((state) => state.langs[0]);
    const dynamicUrlPart = `resume_positions_${lang}`;

    const searchProvider = useMemo(
        () =>
            buildDataProvider(SuggestUrl.Auto, dynamicUrlPart, createSearchFormatter(), getAutosuggestItems, {
                minCharsCount: 2,
            }),
        [dynamicUrlPart]
    );

    return searchProvider;
};
