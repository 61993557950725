import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { ButtonTitle } from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_edit_button_click';
import resumeEditCancelButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_edit_cancel_button_click';
import resumeEditSaveFormSubmit from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_edit_form_submit';
import { resumeEditInterruptionAlertElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_edit_interruption_alert_element_shown';

import { selectResumeEditorType } from 'src/models/applicant/resume/editor/store/selectors';
import { toSnakeCase } from 'src/models/applicant/resume/lib/common/string';
import { selectResumeHash } from 'src/models/applicant/resume/selectors';

import { selectAnalyticsParams } from 'src/models/applicant/resume/analytics/selectors';

// Сабмит формы резюме при нажатии на финальное "Не надо" на модалке "Сохранить изменения?" после редактирования любого блока в составе резюме
export const sendResumeEditCancelButtonClickAnalytics =
    () =>
    (_dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const state = getState();

        const type = selectResumeEditorType(state);
        const resumeHash = selectResumeHash(state);
        const { hhtmFrom, hhtmSource, hhtmSourceLabel, hhtmFromLabel } = selectAnalyticsParams(state);

        resumeEditCancelButtonClick({
            resumeId: resumeHash,
            screenType: toSnakeCase(type) as ButtonTitle,
            hhtmFrom,
            hhtmSource,
            hhtmFromLabel,
            hhtmSourceLabel,
        });
    };

export const sendResumeEditCancelElementShown =
    () =>
    (_dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const state = getState();

        const type = selectResumeEditorType(state);
        const resumeHash = selectResumeHash(state);
        const { hhtmFrom, hhtmSource, hhtmSourceLabel, hhtmFromLabel } = selectAnalyticsParams(state);

        resumeEditInterruptionAlertElementShownRaw({
            resumeId: resumeHash,
            screenType: toSnakeCase(type) as ButtonTitle,
            hhtmFrom,
            hhtmSource,
            hhtmFromLabel,
            hhtmSourceLabel,
        });
    };

// Сабмит формы резюме при нажатии на финальное "Сохранить" на модалке "Сохранить изменения?" после редактирования любого блока в составе резюме
export const sendResumeEditSaveFormSubmitAnalytics =
    (fromAlert = false, errors?: Record<string, unknown>) =>
    (_dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const state = getState();

        const type = selectResumeEditorType(state);
        const resumeHash = selectResumeHash(state);
        const { hhtmFrom, hhtmSource, hhtmSourceLabel, hhtmFromLabel } = selectAnalyticsParams(state);

        resumeEditSaveFormSubmit({
            resumeId: resumeHash,
            formName: fromAlert ? 'resume_edit_alert' : 'resume_edit',
            errors: errors ? JSON.stringify(errors) : null,
            screenType: toSnakeCase(type) as ButtonTitle,
            hhtmFrom,
            hhtmSource,
            hhtmFromLabel,
            hhtmSourceLabel,
        });
    };
