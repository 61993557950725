import { ComponentType, PropsWithChildren } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Card, VSpacingContainer, Cell, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';

import BlockLabel from 'src/pages/ResumeView/redesign/common/BlockLabel';
import EditButton from 'src/pages/ResumeView/redesign/common/EditButton';
import EditIcon from 'src/pages/ResumeView/redesign/common/EditIcon';

type Props = {
    label: string;
    onEdit: VoidFunction;
} & PropsWithChildren;

const TrlKeys = {
    buttonText: 'resume.editor.button.text',
};

const ResumeCard: ComponentType<Props> = translation(({ trls, children, label, onEdit }) => {
    const { isMobile } = useBreakpoint();

    return (
        <VSpacingContainer default={12}>
            <BlockLabel>{label}</BlockLabel>
            <Card stretched borderWidth="default" borderRadius={24} padding={24}>
                {isMobile ? children : <Cell right={<EditIcon onClick={onEdit} />}>{children}</Cell>}
                {isMobile && <VSpacing default={24} />}
                <EditButton onClick={onEdit}>{trls[TrlKeys.buttonText]}</EditButton>
            </Card>
        </VSpacingContainer>
    );
});

export default ResumeCard;
